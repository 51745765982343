<template>
  <el-dialog
      title="是否查看此机会完整手机号及微信号？"
      v-if="showTelephoneObj.isShow"
      :visible.sync="showTelephoneObj.isShow"
      width="30%">
<!--    <span>已查看 {{ checkPhone }}/{{ phoneCount }}</span><br/>-->
    <span v-if="showResult">手机号：{{ telephone }}
        <el-tooltip class="item" effect="dark" content="点击复制手机号" placement="bottom" v-if="telephone">
            <i class="el-icon-document-copy"
               v-clipboard:copy="telephone"
               v-clipboard:success="onCopy"
               v-clipboard:error="onError"></i>
          </el-tooltip>

    </span><br/>
    <span v-if="showResult">微信号：{{ weChat }}
        <el-tooltip class="item" effect="dark" content="点击复制手机号" placement="bottom" v-if="weChat">
            <i class="el-icon-document-copy"
               v-clipboard:copy="weChat"
               v-clipboard:success="onCopy"
               v-clipboard:error="onError"></i>
          </el-tooltip>
    </span><br/>

    <span slot="footer" class="dialog-footer">
    <el-button size="mini" @click="showTelephoneObj.isShow = false">取 消</el-button>
    <el-button size="mini" type="primary" @click="checkWeChat">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
import {checkWeChat, lookPhoneCount} from "../utils/api";

export default {
  name: "GgdShowTelephone",
  props: {
    showTelephoneObj: {
      type: Object,
      default: null
    },
    opportunityId: {
      type: Number,
      default: 0,
      require: true
    }
  },
  data() {
    return {
      checkPhone: 0,
      phoneCount: 0,
      showResult: false,
      telephone: "",
      weChat: ""
    }
  },
  methods: {
    lookPhoneCount() {
      lookPhoneCount().then(res => {
        if (res.success) {
          let resEntity = res.data
          this.checkPhone = resEntity.checkPhone
          this.phoneCount = resEntity.phoneCount
        }
      })
    },
    checkWeChat() {
      checkWeChat(this.opportunityId).then(res => {
        if (res.success) {
          console.log(res)
          let resEntity = res.data
          this.telephone = resEntity.telephone
          this.weChat = res.weiXin
          this.showResult = true
        } else {
          this.$message.warning(res.msg)
        }
      }).catch(error => {
        this.$message.error("查看手机号失败")
      })
    },
    onCopy(e) {    // 复制成功
      this.$message({
        message: '复制成功！',
        type: 'success'
      })
    },
    onError(e) {   // 复制失败
      this.$message({
        message: '复制失败！',
        type: 'error'
      })
    },
  },
  watch: {
    "showTelephoneObj.isShow": function (newValue, oldValue) {
      if (newValue) {
        this.showResult = false
        this.telephone = ""
        this.weChat = ""
      }
    }
  },
  mounted() {
    // this.lookPhoneCount()
  }
}
</script>

<style scoped>

</style>
